<template>
	<v-container>
		<material-card title="Обновления">
			<Progress :error="error" :loading="loading"></Progress>
			<v-list v-if="items">
				<template v-for="(item, id) in items">
					<new-item v-if="channels[item.channel_id]" :item="item" :channel="channels[item.channel_id]" :key="id"/>
				</template>
				<v-list-item>
					<v-flex xs12 class="text-md-center">
						<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
					</v-flex>
				</v-list-item>
			</v-list>
		</material-card>
	</v-container>
</template>

<script>
import ScrollPagination from "../components/helper/scroll-pagination.vue";
import NewItem from "../components/new-item.vue";

export default {
	components: { ScrollPagination, NewItem },
	data() {
		return {
			loading: true,
			items: null,
			error: null,
			hasMorePage: false,
			page: 1,
			channels: null
		};
	},
	created() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/new", { body: { page: t.page } }, function(
				data,
				error
			) {
				t.loading = false;
				if (!error) {
					t.hasMorePage = data.hasMore;
					if (t.page == 1) {
						t.channels = data.channels;
						t.items = data.items;
						t.$store.commit("ws/setCount", { ncu: 0 });
					} else {
						t.items = [...t.items, ...data.items];
					}
				} else {
					t.error = data;
				}
			});
		},
		onScroll(page) {
			this.page = page;
			this.fetchData();
		}
	}
};
</script>
<style scoped>
.container {
	max-width: 1100px;
}
</style>