<template>
	<div>
		<v-list-item
			:to="{ name: 'channelPage', params: { address: channel.address }}"
			v-touch="{
				left: () => unsubscribe(channel),
				right: () => unsubscribe(channel)
			}"
			:style="del ? 'background-color:#eeaaaa;' : ''"
		>
			<v-list-item-action>
				<v-img
					:title="channel.title"
					:src="getPhotoPath('channel', channel.id) + channel.photo + '_48.jpg'"
					:srcset="getPhotoPath('channel', channel.id) + channel.photo + '_180.jpg 2x'"
					max-width="48"
					eager
				></v-img>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title v-html="channel.title" />
				<v-list-item-subtitle>
					Дата обновления: {{ humanDate(item.date) }}
					<b>{{ item.comment }}</b>
					<br />
					Вы смотрели {{ channel.ago }}
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action class="menu">
				<v-icon v-if="!del" @click.prevent="unsubscribe(channel)" title="Отписаться">icon-close</v-icon>
				<v-icon v-else @click.prevent="unsubscribe(channel)" title="Подписаться обратно">icon-bookmark</v-icon>
			</v-list-item-action>
		</v-list-item>
		<v-list-item :key="item.channel_id">
			<video-list :items="item.list" channel_title="true"></video-list>
		</v-list-item>
	</div>
</template>

<script>
import { Helper } from "../mixins/Helper";
import VideoList from "../components/video-list.vue";
import { Touch } from "vuetify/lib/directives";

export default {
	props: ["item", "channel"],
	mixins: [Helper],
	components: { VideoList },
	directives: { Touch },
	data() {
		return {
			del: false
		};
	},
	methods: {
		unsubscribe(item) {
			let t = this,
				yes = true;
			if (!item.del) yes = confirm("Вы уверены, что хотите отписаться?");
			if (!yes) return;

			window.ajax("/api/subscribe/set/" + item.id, [], function(
				data,
				error
			) {
				if (!error) {
					t.del = !data.status;
				}
			});
		}
	}
};
</script>
<style scoped>
.menu {
	display: none;
}
.v-list-item:hover .menu {
	display: block;
}
@media only screen and (max-width: 600px) {
	.v-list-item__subtitle .new {
		display: block;
	}
}
</style>